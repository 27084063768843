<template>
  <div class="meus-dados">
    <Voltar />
    <h1 class="titulo">Meus dados</h1>

    <div class="bt">
      <a type="button" @click="() => router.push({ name: 'EditarPerfil' })"> Editar perfil </a>
    </div>
    <div class="bt">
      <a type="button" @click="() => router.push({ name: 'FormaPagto' })"> Forma de pagamento </a>
    </div>
    <div class="bt">
      <a type="button" @click="() => router.push({ name: 'HistoricoPagamentos' })"> Histórico de pagamento </a>
    </div>
    <div class="bt">
      <a type="button" @click="() => router.push({ name: 'TermoUso' })"> Termos de Uso </a>
    </div>
    <div class="bt">
      <a type="button" @click="() => router.push({ name: 'Regulamento' })"> Regulamento </a>
    </div>
    <div class="bt">
      <a type="button" @click="() => router.push({ name: 'Politica' })"> Política de Privacidade </a>
    </div>
    <div class="bt bt--destaque" v-if="user.assinatura">
      <a type="button" @click="() => router.push({ name: 'Cancelamento' })"> Cancelar assinatura </a>
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
import Voltar from '../../components/Voltar'
export default {
  setup () {
    const router = useRouter()
    const user = JSON.parse(window.localStorage.getItem('user'))

    return {
      router,
      user
    }
  },
  components: { Voltar }
}
</script>
